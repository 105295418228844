<template>
	<div class="d-flex flex-column devicesmappanel fill-height max-height-100">
		<div class="d-flex flex-column py-3 px-3 bottomborder" ref="header">
			<div class="devices-title">{{ $t('panels.devicespanel.devices') }}</div>
			<div class="devices-subtitle fw-regular fs-sm">{{ $t('panels.devicespanel.subtitle') }}</div>
		</div>

		<div class="lists pb-0" style="min-height: 30%">
			<v-list flat dense style="background: transparent" class="pt-0">
				<!-- Devices -->
				<div v-if="showDevices">
					<!-- 					<v-list-group id="deviceslist" :ripple="false" v-model="isOpened.devices">
 -->
					<!-- <template #activator>
							<v-list-item-title class="maintitle ml-n4">{{ $t('panels.configpannel.vectorialLayers') }}</v-list-item-title>
						</template>

						<template #prependIcon>
							<v-icon class="fw-bold" :color="isOpened.devices ? 'var(--azul-primary-100)' : '#000'" size="13"
								>fak fa-solid-layers</v-icon
							>
						</template>

						<template #appendIcon>
							<v-icon class="fw-bold list-icon" :class="{ 'list-icon--opened': isOpened.devices }" size="13"
								>fas fa-chevron-down</v-icon
							>
						</template> -->

					<v-list-item class="px-3">
						<devices-list ref="deviceslist" />
					</v-list-item>
					<!-- </v-list-group> -->
				</div>

				<v-divider style="height: 1px; background: rgba(0, 0, 0, 0.05)" :class="{ 'mt-2': isOpened.devices }" />
			</v-list>
		</div>
	</div>
</template>

<script>
import DevicesList from './DevicesList';

export default {
	name: 'devicesmappanel',
	data() {
		return {};
	},
	components: {
		DevicesList
	},

	computed: {
		isOpened() {
			return this.$store.state.devicesPanel.isOpened;
		},
		showDevices() {
			return Object.keys(this.$store.getters.getDevices).length > 0;
			//Object.values(this.$store.getters.getDevicesData).filter((device) => device.spatialmodel != constants.SATELLITE_MODEL).length > 0;
		}
	},

	created() {
		console.log('show devices: ' + this.showDevices);
		console.log(Object.keys(this.$store.getters.getDevices).length > 0);
		console.log(Object.keys(this.$store.getters.getDevices).length);
		console.log(this.$store.getters.getHasDevices);
	},

	methods: {}
};
</script>

<style lang="postcss">
@import '../../../../../styles/eiffel-variables.pcss';
.devicesmappanel {
	& .list-icon {
		color: var(--azul-primary-100);

		&--opened {
			transform: rotateZ(-90deg) !important;
		}
	}

	& .lists {
		overflow-x: hidden;
		overflow-y: auto;

		& .maintitle {
			font-weight: 700;
			font-size: 1.1rem;
			font-weight: bold;
			color: #000;
		}
	}

	& .devices-title {
		font-size: 1.3rem;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: #000;
		margin-bottom: 2px;
	}

	& .devices-subtitle {
		font-style: normal;
		font-stretch: normal;
		line-height: 1.33;
		letter-spacing: normal;
		color: var(--gris-500);
		margin-top: 2px !important;
	}
}
.bottomborder {
	border-bottom: 1px solid var(--moderate);
}
</style>
