<template>
	<v-tooltip bottom :disabled="!disabledItem" :color="primaryColor">
		<template v-slot:activator="{ on, attrs }">
			<div class="layer-list" :class="{ 'border-bottom': borderBottom }">
				<v-list-group
					sub-group
					@click.native.stop="opened = !opened"
					:value="opened"
					:ripple="false"
					:append-icon="undefined"
					active-class="active"
					:class="noPadding ? 'no-padding' : false"
				>
					<template #prependIcon>
						<v-icon v-if="!!$slots.default" size="13" class="high">
							fal
							{{ opened ? 'fa-minus' : 'fa-plus' }}
						</v-icon>
						<div v-else />
					</template>

					<template #activator>
						<v-list-item-title
							v-bind="attrs"
							v-on="on"
							class="layer-item color-black fw-500 fs-regular ml-n4"
							:class="{ disabled: disabledItem }"
							>{{ title }}
						</v-list-item-title>
					</template>

					<template #appendIcon>
						<div class="d-flex align-center">
							<v-tooltip right max-width="350" nudge-right="5" transition="scroll-x-transition" :color="primaryColor + 'd9'">
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										text
										icon
										class="cardheaderbtn ml-auto"
										@click="centerOnDevice(device)"
										:disabled="!active"
										v-bind="attrs"
										v-on="on"
									>
										<i class="fas fa-arrow-right"></i>
									</v-btn>
								</template>
								<span class="fw-500 fs-regular text-center">{{ $t('panels.devicespanel.centerOnDevice') }}</span>
							</v-tooltip>
							<!-- <pui-switch
								:disabled="disabledItem"
								color="blue darken-1"
								class="mt-0 pt-0"
								v-model="computedActive"
								@click.native.stop=""
								@input="(value) => (computedActive = value)"
							></pui-switch> -->
						</div>
					</template>
				</v-list-group>
			</div>
		</template>
		<span class="fs-regular">{{ $t('panels.devicespanel.tooltipDisabledDevice') }}</span>
	</v-tooltip>
</template>

<script>
export default {
	props: {
		value: {
			type: Boolean,
			required: true
		},

		active: {
			type: Boolean,
			required: true
		},

		title: {
			type: String,
			require: true
		},

		borderBottom: {
			type: Boolean,
			default: false
		},

		disabledItem: {
			type: Boolean,
			require: true
		},
		noPadding: {
			type: Boolean,
			required: false
		},
		device: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			featurePopup: null,
			primaryColor: this.$store.state.global.primaryColor
		};
	},

	computed: {
		opened: {
			get() {
				return this.value;
			},

			set(value) {
				this.$emit('input', value);
			}
		},

		computedActive: {
			get() {
				console.log(this.active);
				return this.active;
			},

			set(value) {
				this.$emit('update:active', value);
			}
		}
	},
	methods: {
		centerOnDevice(device) {
			if (device) {
				this.$store.commit('setDeviceZoom', device);
			}
		}
	}
};
</script>

<style lang="postcss">
@import '../../../../../../../styles/eiffel-variables.pcss';

.layer-list {
	& .border-bottom {
		border-bottom: 1px #fff solid;
	}

	& .high {
		color: var(--high) !important;
	}

	& .fw-bold {
		font-weight: 700;
	}
	& .disabled {
		color: var(--moderate) !important;
	}
	& .cardheaderbtn {
		color: var(--high);
	}
	& .no-padding > .v-list-item {
		padding: 0px !important;
	}
}
</style>
